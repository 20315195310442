<template>
	<div class="base_box">
		
		<div class="base_form base_form_padding  " style="border-radius:5px; width: 600px !important;margin: 20px auto;overflow-y: auto;">
			<div class="base_form_title">完善店铺信息</div>
			<el-form class="mt20" ref="form" label-width="90px">
				<el-form-item label="店铺名称:">
					<el-input placeholder="请输入店铺名称" size="small" v-model="post.title" type="text" style="width: 450px;"></el-input>
				</el-form-item>
				<!-- <el-form-item label="店铺头像:" style="margin-bottom: 16px;"  >
					<div class="base_img_size" @click="imgplugin.show=true;imgplugin.imgName='image'">
						<el-image class="base_img_size_img" style="width: 70%;height: 70%;" v-if="post.image" :src="post.image_url"
							fit="cover"></el-image>
						<i v-if="!post.image" class="el-icon-plus base_img_size_addicon"></i>
						<div v-if="!post.image" class="base_img_size_text">添加头像</div>
					</div>
					<div class="base_tips mt10">请上传店铺头像,建议尺寸200*200</div>
				</el-form-item> -->
				<el-form-item label="所选应用:">
					<el-input placeholder="请输入所选应用" disabled size="small" v-model="post.application" type="text" style="width: 450px;"></el-input>
				</el-form-item>
				<el-form-item label="所选行业:">
					<el-select size="small" class="input" v-model="post.industry_id" placeholder="请选择所选行业" clearable style="width: 450px;">
					  <el-option v-for="(i,index) in industryList" :label="i.title" :value="i.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺年限:" style="margin-bottom: 16px;" >
					<el-input placeholder="请输入店铺年限" disabled size="small" value="15天" type="text" style="width: 450px;"></el-input>
					<div class="base_tips">店铺创建成功有，有15天使用期，如需正常使用，请联系客服人员</div>
				</el-form-item>
				<el-form-item label="公司名称:">
					<el-input placeholder="请输入公司名称" size="small" v-model="post.company_name" type="text" style="width: 450px;"></el-input>
				</el-form-item>
				<el-form-item label="联系姓名:">
					<el-input placeholder="请输入联系姓名" size="small" v-model="post.name" type="text" style="width: 450px;"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					<el-input placeholder="请输入联系电话" size="small" v-model="post.phone" type="text" style="width: 450px;"></el-input>
				</el-form-item>
				<el-form-item label="验证号码:">
					<el-input placeholder="请输入验证号码" size="small" v-model="post.code" type="text" style="width: 450px;">
						<template slot="suffix">
							<span :style="{color:post.phone.length==11?'#1989fa':'#999'}" class="getcode" @click="getCode">{{code.title}}</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="店铺地区:">
					<el-cascader placeholder="请选择店铺所在的省市区" class="input" size="small" :props='{ children: "children", value: "name", label: "name" }' :value="post.addressAres" :options="areaData" @change="addressFun" style="width: 450px;"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址:">
					<el-input placeholder="请输入详细地址" size="small" v-model="post.address" type="text" style="width: 450px;"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;margin-bottom: 30px;">
				<el-button type="info" class="base_grey_but base_but_style base_return_but"  plain @click="toback">返回</el-button>
				<el-button type="primary" class="base_but_style base_save_but" @click="sendinfo">保存</el-button>
			</div>
		</div>

		<xsk-manage :managehide.sync="imgplugin.show" @confirm="imgpluginFun" :multiple="imgplugin.multiple" :count="imgplugin.count" is_rest>
		</xsk-manage>
	</div>
</template>

<script>
	import {
		eltips
	} from "@/util/util.js";
	import TinymceEditor from "@/components/TinymceEditor/TinymceEditor";
	import draggable from 'vuedraggable';
	export default {
		name: "basics",
		components: {
			draggable,
			TinymceEditor
		},
		data() {
			return {
				title:'配置',
				post:{
					application_id:'',
					application:'',
					title:'',
					industry_id:'',
					province:'',
					city:'',
					area:'',
					address:'',
					name:'',
					phone:'',
					code:'',
					image:0,
					image_url:'',
					company_name:''
				},
				roleList:[],
				industryList:[],
				imgplugin:{
					show:false,
					multiple:false,
					count:1,
					imgName:''
				},
				areaData:[],
				code:{
					inter:null,
					time:60,
					title:'发送验证码'
				}
			};
		},
		async created() {
			let _this = this
			_this.post.application = _this.$route.query.title
			_this.post.application_id = _this.$route.query.id
			_this.axios.get('/api/shop/basic/industry-index').then((res) => {
				if(res.code == 200){
					_this.industryList = res.data.data
				}else{
					eltips(res.msg, "error");
				}
			})
			await _this.axios.get('/api/common/get_area_tree').then((res) => {
				if(res.code == 200){
					_this.areaData = res.data
				}else{
					eltips(res.msg, "error");
				}
			})
		},
		methods: {
			getCode(){
				let _this = this
				if(this.post.phone.length<0){
					eltips('手机号不能为空', "error");
					return
				}
				if(this.code.time!=60){
					return
				}
				_this.axios.post('/api/common/send-sms',{
					  mobile:this.post.phone,
					  type:0
				}).then((res) => {
					if(res.code == 200){
						_this.code.inter = setInterval(function(){
							if(_this.code.time<=1){
								clearInterval(_this.code.inter)
								_this.code.title = '发送验证码'
								_this.code.time = 60
								_this.code.inter = null
								return false
							}
							_this.code.time--
							_this.code.title = _this.code.time+' S'
						},1000)
					}else{
						_this.code.title = '发送验证码'
						_this.code.time = 60
						_this.code.inter = null
						eltips(res.msg, "error");
					}
				})
				
			},
			addressFun(e){
				this.post.province = e[0]
				this.post.city = e[1]
				this.post.area = e[2]
			},
			//图片管理器回传
			imgpluginFun(e) {
				if (e.imgobjs.length == 0) {
					return
				}
				this.post[this.imgplugin.imgName] = e.imgobjs[0]['id']
				this.post[this.imgplugin.imgName+'_url'] = e.imgobjs[0]['url']
			},
			toback() {
				this.$router.go(-1);
			},

			sendinfo() {
				let _this = this
				this.axios.post('/api/shop/basic/finish-info',this.post).then((res) => {
					if(res.code == 200){
						eltips(res.msg, "success");
					}else{
						eltips(res.msg, "error");
					}
				})
			}

		},
	};
</script>


<style lang="less" scoped>
	.getcode{
		height:36px;
		line-height:26px;
		cursor: pointer;
		color:#999
	}
</style>